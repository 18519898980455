.asset-list-card {
  position: relative;
}

.customer-name {
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 30px;
}

.site-name, .service-tag {
  display: flex;
  justify-content: space-between;
}

.csv-export-button {
  top: -50px;
}

.results-counter {
  position: absolute;
  bottom: 30px;
  right: 25px;
  color: grey
}