@use './assets/scss/tailwind.scss';
@use './assets/scss/base.scss';
@use './assets/scss/ant-customizations.scss';

$primary-color: #d1e0d7;

::-moz-selection {
  background: $primary-color !important;
  color: inherit !important;
}

::selection {
  color: inherit !important;
  background: $primary-color !important;
}
