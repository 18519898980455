@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  display: flex;
  flex-flow: column;
}

body,
html {
  min-height: 100%;
}

body {
  font-family: 'Sora', sans-serif;
}

body,
#root {
  display: flex;
  flex: 1 0 100%;
  flex-flow: column;
  overflow-x: auto;
}

main {
  display: flex;
  flex-grow: 1;
}

ul {
  list-style: none;
}

.site-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f4f4f4;
}

section {
  padding: 0rem 1rem 1rem 1rem;
}

.client-dashboard {
  .view-customer {
    display: none;
  }
}

.cta-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.clear-button {
  margin-left: 10px;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1e0d7;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #004e42;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #004e42;
}
