.ant-btn-primary:hover {
  opacity: 0.7;
  @apply bg-ncr-brand-dark-teal;
}

.ant-btn-link:hover {
  opacity: 0.8;
  @apply text-ncr-brand-dark-teal;
}

.ant-btn-primary {
  @apply bg-ncr-brand-dark-teal;
  border-radius: 5px;
  border: none;
}

.ant-btn-default {
  border-radius: 5px;
}

.ant-btn-lg {
  border-radius: 5px;
}

section.ant-layout {
  background-color: white;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover {
  &:after {
    @apply border-b border-solid border-ncr-brand-dark-teal;
  }
}

.ant-layout-sider {
  background-color: #fff;
  border-right: 1px solid #f4f4f4;
}

section.ant-layout {
  background-color: white;
}

.ant-layout-header {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    @apply border-b border-solid border-ncr-brand-dark-teal;
  }
  .ant-menu {
    border-radius: 5px;
    margin: 1rem 1rem;
    @apply border-b border-solid border-ncr-brand-dark-teal;
    &.ant-menu-light {
      background: #fff;
      a {
        color: #333;
      }
    }
    &.ant-menu-dark {
      background: #fff;
      a {
        color: #333;
      }
    }
  }
}

.ant-layout-sider {
  background-color: #fff;
  border-right: 1px solid #f4f4f4;
}

.ant-alert-info {
  @apply border border-ncr-darker;
  background-color: #eaebee;
}

.ant-btn > span.hidden {
  display: none;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin: 0;
}

.ant-modal-content {
  @apply border border-solid border-ncr-brand-light-teal;
  border-radius: 10px;
  overflow: hidden;
  .ant-modal-title {
    font-size: 1.5rem;
  }
}

.ant-progress-circle .ant-progress-text,
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  @apply text-ncr-darker;
}

.asset-creation .ant-tabs-top > .ant-tabs-nav {
  @apply mb-12;
}

.ant-tabs-content-holder.loading {
  @apply flex-1;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #eaebee;
}

tr.ant-table-row.ant-table-row-level-0 {
  background: #fafafa;
}

@media ant-table-row and (max-width: 900px) {
  #main {
    overflow-x: scroll;
  }
}

.ant-tag-yellow {
  border-color: #d4b106;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply text-white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #2c9912;
}

.ant-input {
  @apply text-ncr-brand-dark-teal;
}

.ant-input-affix-wrapper {
  border-radius: 5px;
  &:hover {
    @apply border-ncr-brand-dark-teal;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  @apply border-ncr-brand-dark-teal;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  @apply border-ncr-brand-dark-teal;
  @apply shadow;
  @apply shadow-ncr-brand-light-teal;
}

.ant-card {
  @apply border border-solid border-ncr-brand-light-teal rounded-md shadow-md;
}

.ant-card-head {
  .ant-card-head-title {
    font-size: 1.5rem;
    @media (max-width: 900px) {
      font-size: 1rem;
    }
  }
}

.ant-tabs {
  &.ant-tabs-left {
    > .ant-tabs-nav {
      @apply border border-solid border-ncr-brand-light-teal rounded-md shadow;
      background-color: #f5f5f5;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          background-color: #fff;
          .ant-tabs-ink-bar {
            width: 5px;
          }
        }
      }
    }
  }
  .ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        border-radius: 5px;
        padding: 0.5rem;
        border: none;
        .ant-tabs-ink-bar {
          @apply bg-ncr-brand-dark-teal;
        }
        .ant-tabs-tab {
          padding: 0.5rem;
          .ant-tabs-tab-btn {
            color: inherit;
            font-weight: normal;
            text-shadow: none;
            &:hover {
              @apply text-ncr-brand-dark-teal;
            }
          }
          &.ant-tabs-tab-active {
            background-color: #fff;
            border: none;
            border-radius: 5px;
            .ant-tabs-tab-btn {
              color: inherit;
              font-weight: normal;
              text-shadow: none;
              &:hover {
                @apply text-ncr-brand-dark-teal;
              }
            }
          }
        }
      }
    }
  }
}

.single-asset-view-container {
  position: relative;
  .close-asset-view-btn {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 0px;
    border-radius: 5px;
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
  }
}

.full-width-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.custom-tabs {
  & > .ant-tabs-nav {
    & > .ant-tabs-nav-wrap {
      background-color: #fff;
      @apply border border-solid border-ncr-brand-light-teal rounded-md shadow;
    }
  }
  .inner-tabs {
    .ant-tabs-nav-list {
      background-color: #f5f5f5;
    }
  }
}

.asset-approval-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    @apply text-ncr-brand-dark-teal border-ncr-brand-dark-teal;
    background: #feffe6;
  }
}

.tag-link {
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.search-bar {
  .ant-input-group-wrapper {
    &.disabled {
      background-color: #f5f5f5;
      .ant-input-group {
        .ant-input-affix-wrapper {
          cursor: not-allowed;
          pointer-events: none;
          &:focus {
            border-color: #f5f5f5;
            box-shadow: none;
          }
          .ant-input {
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
    .ant-input-group {
      .ant-input-affix-wrapper {
        &:hover,
        &:focus {
          @apply border-ncr-brand-light-teal;
        }
        &:not(:first-child) {
          .ant-input-search {
            input {
              &:hover {
                @apply border-ncr-brand-light-teal;
              }
            }
          }
        }
      }
      .ant-input-group-addon {
        left: 0;
      }
    }
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  @apply border-none border-transparent shadow-none;
}

.ant-pagination-options-size-changer.ant-select-focused:not(
    .ant-select-disabled
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  @apply shadow-none;
}

.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: inherit !important;
}

.ant-select-item-option-content {
  color: #0a0a0a;
}

.ant-tag {
  position: relative;
  .badge-count {
    position: absolute;
    top: -10px;
    right: -15px;
  }
}
.ant-form-item {
  p {
    margin-bottom: 0px;
    color: red;
  }
}

.snaps {
  .ant-collapse-header-text {
    font-size: 16px;
    font-weight: 600;
  }
  .service-history-lists {
    display: flex;
  }
  .service-list {
    margin: 0.5rem;
    flex-grow: 1;
    .added-services {
      .ant-list-header {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background-color: #68db8c;
      }
    }
    .removed-services {
      .ant-list-header {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background-color: #ff8080;
      }
    }
    .current-services {
      .ant-list-header {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        background-color: #f4f4f4;
      }
    }
  }
  // ul {
  //   padding-left: 20px;
  //   width: 100%;
  //   li {
  //     width: 100%;
  //     margin: 1rem 0;
  //   }
  // }
  .service-panel {
    margin-top: 1rem;
  }
  .status-list {
    width: 100%;
  }
}

.ant-table-cell {
  .ant-tag {
    display: table;
    text-align: center;
    width: auto;
    margin-bottom: 0.25rem;
    white-space: normal;
  }
}

.no-data-tag {
  padding: 2rem;
}

.ant-card-head-title {
  white-space: pre-wrap;
}

.ant-select-dropdown {
  * {
    text-align: left;
  }
}

.ant-table-content {
  overflow-x: auto;
  overflow-y: hidden;
}

.ant-pagination-item {
  font-family: inherit !important;
}
.ant-pagination-item-active {
  @apply border-ncr-brand-dark-teal;
  a {
    @apply text-ncr-brand-dark-teal;
  }
}
.ant-pagination-item:hover {
  @apply border-ncr-brand-dark-teal;
  a {
    @apply text-ncr-brand-dark-teal;
  }
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: inherit !important;
  button {
    &.hover {
      @apply text-ncr-brand-dark-teal;
      @apply border-ncr-brand-dark-teal;
    }
  }
}
